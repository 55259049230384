<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
        md="6"
      >
        <v-card
          flat
          class="pa-3"
        >
          <v-card-title>
            {{ error }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ERROR_404, ERRORS } from '../model/errors'

@Component
export default class ErrorView extends Vue {
  @Prop({
    type: [Number, String],
    default: () => 404
  }) code

  get internal () {
    return ERRORS.includes(this.code.toString()) ? this.code : ERROR_404
  }

  get error () {
    return this.$t(`error.${this.internal}`)
  }
}
</script>

<i18n>
{
  "de": {
    "error": {
      "404": "Sie haben einen ungültigen oder nicht mehr gültigen Link aufgerufen.",
      "100450": "Die Bewertung zu diesem Trendradar wurde bereits von den Projektmanagern geschlossen. Sie können nicht länger an der Bewertung teilnehmen."
    }
  },
  "en": {
    "error": {
      "404": "You have accessed an invalid or no longer valid link.",
      "100450": "The evaluation for this trend radar has already been concluded by the project managers. You can no longer participate in the evaluation."
    }
  }
}
</i18n>
